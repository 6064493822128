<template>
    <div class="teacherDetail">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="teacherDetail_information">
            <img src="@/assets/teacher/teacher_banner.png" alt="" class="teacherDetail_information_banner">
            <div class="teacherDetail_information_box">
                <div class="teacherDetail_information_box_left">
                    <img :src="teacherObj.imagePath" alt="">
                </div>
                <div class="teacherDetail_information_box_right">
                    <div class="teacherDetail_information_box_right_name">{{teacherObj.teacherName}}</div>
                    <div class="teacherDetail_information_box_right_expertise">
                        <span>主讲课程:</span><span v-for="(item,index) in teacherObj.expertiseAreaNameList" :key="index">{{item}}</span>
                    </div>
                    <div class="teacherDetail_information_box_right_intro" style="text-align:left;">{{teacherObj.introduction}}</div>
                    <div class="teacherDetail_information_box_right_trait">
                        <div class="teacherDetail_information_box_right_trait_title">
                            <img src="@/assets/teacher/trait.png" alt="">
                            <span style="margin-left:10px;">授课特点</span>
                        </div>
                        <div class="teacherDetail_information_box_right_trait_text">{{teacherObj.teacherTeachStyle}}</div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="teacherDetail_main">
            <div class="teacherDetail_main_left">
                <div class="teacherDetail_main_left_title">
                    <img src="@/assets/teacher/class_icon.png" alt="">
                    <span>TA的课程</span>
                </div>
                <div class="teacherDetail_main_left_list">
                    <div class="teacherDetail_main_left_list_item" v-for="(item,index) in teacherCourseList" :key="index" @click="todetail(item)">
                        <div class="teacherDetail_main_left_list_item_top">
                            <img :src="item.coverPath" alt="">
                        </div>
                        <div class="teacherDetail_main_left_list_item_bottom">
                            <div class="teacherDetail_main_left_list_item_bottom_text">{{item.packageName}}</div>
                            <div class="teacherDetail_main_left_list_item_bottom_price">
                                <p v-if="item.isFree != 0">
                                    <span class="color_red" v-if="item.isDiscount==1">限时折扣：¥{{item.discountPrice}}</span>
                                    <span :class="item.isDiscount==1?'color_ccc':'color_red'">¥{{item.price}}</span>
                                </p>
                                <p v-if="item.isFree == 0">
                                    <span class="color_blue">免费</span>
                                </p>

                                <!-- <p class="teacherDetail_main_left_list_item_bottom_price_discount" v-if="item.free==1">
                                    <span>限时折扣：¥</span>
                                    <span>{{item.discountPrice}}</span>
                                </p>
                                <p class="teacherDetail_main_left_list_item_bottom_price_free" v-if="item.free==0">免费</p>
                                <p class="teacherDetail_main_left_list_item_bottom_price_original">{{item.price}}</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="coursediv_empty" v-if="!teacherCourseList.length">
                        <div class="coursediv_empty_img">
                            <img src="@/assets/class_empty.png" alt="">
                        </div>
                        <div class="coursediv_empty_text">无相关内容</div>
                    </div>
                </div>
            </div>
            <div class="teacherDetail_main_right">
                <div class="teacherDetail_main_right_title">推荐课程</div>
                <!-- <div class="teacherDetail_main_right_item" v-for="(item,index) in recommendList" :key="index">
                    <img :src="item.coverPath" alt="">
                    
                </div> -->
                <div class="teacherDetail_main_left_list_item" v-for="(item,index) in recommendList" :key="index" @click="todetail(item)">
                        <div class="teacherDetail_main_left_list_item_top">
                            <img :src="item.coverPath" alt="">
                        </div>
                        <div class="teacherDetail_main_left_list_item_bottom">
                            <div class="teacherDetail_main_left_list_item_bottom_text">{{item.packageName}}</div>
                            <div class="teacherDetail_main_left_list_item_bottom_price">
                                <p v-if="item.isFree != 0">
                                    <span class="color_red" v-if="item.isDiscount==1">限时折扣：¥{{item.discountPrice}}</span>
                                    <span :class="item.isDiscount==1?'color_ccc':'color_red'">¥{{item.price}}</span>
                                </p>
                                <p v-if="item.isFree == 0">
                                    <span class="color_blue">免费</span>
                                </p>

                                <!-- <p class="teacherDetail_main_left_list_item_bottom_price_discount" v-if="item.free==1">
                                    <span>限时折扣：¥</span>
                                    <span>{{item.discountPrice}}</span>
                                </p>
                                <p class="teacherDetail_main_left_list_item_bottom_price_free" v-if="item.free==0">免费</p>
                                <p class="teacherDetail_main_left_list_item_bottom_price_original">{{item.price}}</p> -->
                            </div>
                        </div>
                    </div>

            </div>
        </div>
        <t_footer></t_footer>
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { teacher_list,teacher_courseList } from "@/api/other";
import { classRecommend_list } from "@/api/home";

export default {
    name:'teacherDetail',
    components: {
        t_header,
        t_footer,
    },
    data(){
        return{
            teacherObj:{},
            recommendList:[],
            teacherCourseList:[],
            page:0,
            isUserInfo:false,
        }
    },
    mounted(){
        this.teacherObj=JSON.parse(this.$route.query.teacherObj)
        if(this.teacherObj.teacherName){
            document.title = this.teacherObj.teacherName+'-萨恩课堂';
        }
        this.classRecommend_list()
        this.teacher_courseList()
    },
    methods:{
        // 去详情
        todetail(item){
            console.log(item)
            let routeUrl =this.$router.resolve({
                path: '/coursedetail',
                query: {coursePackageId:item.coursePackageId}
            });
            window.open(routeUrl.href, '_blank');
        },
        // 父传子
        getTodos(e){
            console.log(e)
            this.isUserInfo=e
        },
        classRecommend_list(){
            classRecommend_list().then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.recommendList=res.data.data
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        teacher_courseList(){
            var obj={
                page:this.page,
                teachTeacherId:this.teacherObj.teacherId
            }
            teacher_courseList(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.teacherCourseList=res.data.data
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
    }
}
</script>

<style>

</style>